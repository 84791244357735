.competition-container {
  display: flex;
  flex-wrap: wrap; 
  padding: 20px;
  background-image: url("../../assets/Images/Design.png");
  background-size: cover;
  background-position: center;
}

.competition-card {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  padding: 20px;
  text-align: center;
  margin: 20px; 
  margin-left: 10rem;
  background: transparent;

}

.competition-card img {
  width: 100%;
  border-radius: 8px;
}

.competition-card h2 {
  font-size: 1.5em;
  margin: 20px 0 10px;
}

.competition-card p {
  font-size: 1em;
  margin: 10px 0;
}

@media only screen and (max-width: 768px) {
  .competition-card {
    max-width: 100%;
    margin: 20px;
  }

  .competition-container{
    margin-left: 0;
    gap: 0;
  }
}
