html{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.main-div{
    background-image: url('./assets/Images/11.jpg');  
    background-size: cover;
    background-position: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}
.main-content {
  height: 600px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left; 
  text-align: left; 
  margin: 0;
}

.Main {
  text-align: left;
  padding: 20px; 
  margin-right: auto;
  margin-left: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: left;
  height: max-content;
  width: 600px;
}

.social {
  margin-top: auto;
}


.input-button-container {
  display: flex;
  justify-content: center;
}

.input3 {
  height: 40px;
  border-radius: 50px;
  transition: .2s linear;
  border: 2.5px solid #fecc44;
  font-size: 14px;
  letter-spacing: 2px;
  padding: 10px;
  margin: 0;
  margin-right: 10px;
}

.input3:focus {
  outline: none;
  border: 0.5px solid #fecc44;
  box-shadow: -5px -5px 0px #fecc44;
}

.find-food-button {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #cf001a; 
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  width: fit-content;
  text-transform: uppercase; 
  height: 100%;
}

.text1{
  align-self: center;
  padding: 20px;
}
.text1 h1{
  height: max-content;
  font-size: 50px;
  padding: 0;
  color: #fcbf1a;
}
.text1 p{
  font-size: 20px;
}


@media (max-width: 768px) {
  .Main {
    margin: 0 auto;
    width: 80%; 
    height: 100%;
  }
  .main-div{
    background-image: none;
    box-shadow: none;
  }
  .main-content {
    height: max-content; 
    padding: 20px;
  }
  .input-button-container {
    flex-direction: column; 
    align-self: center; 
    margin-bottom: 20px; 
    width: 80%;
  }
  .input3 {
    width: 80%; 
    margin-right: 0; 
    margin-bottom: 10px; 
  }
  .text1{
    align-self: center;
    text-align: center ;
  }
  .text1 h1{
    font-size: 30px;
  }
  .text1 p{
    font-size: 15px;
  }
  .find-food-button{
    margin-top: 10px;
  }
}
