.app__footer {
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: #fecc44;
    padding-top: 0;
    padding-bottom: 20px;
    font-family: Georgia, 'Times New Roman', Times, serif;
}
p{
    font-family: Georgia, 'Times New Roman', Times, serif;
}
.app__footer-links {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.app__footer-links_contact,
.app__footer-links_logo,
.app__footer-links_work {
    flex: 1;
    text-align: center;
}

.app__footer-links_logo img:first-child {
    margin-bottom: 0.5rem;
    height: 200px;
    width: 200px;
}


.app__footer-links_icons {
    margin-top: 0.5rem;
}

.app__footer-links_icons svg {
    color: var(--color-white);
    margin: 01.3rem;
    font-size: 80px;
    cursor: pointer;
}

.app__footer-links_icons svg:hover {
    color: var(--color-golden);
}

.app__footer-headtext {
    font-family: var(--font-base);
    color: var(--color-white);
    font-weight: 550;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    font-size: 50px;
    line-height: 41.6px;
    margin-bottom: 2rem;
    padding-top: 7rem;
}

.footer__copyright {
    margin-top: 3rem;
    text-align: center;
    width: 100%;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

@media screen and (min-width: 2000px) {
    .app__footer-headtext {
        font-size: 51px;
        line-height: 61.6px;
    }
}

@media screen and (max-width: 1150px) {
    .app__footer-links {
        align-items: center;
        flex-direction: column;
    }

    .app__footer-links_contact,
    .app__footer-links_work {
        margin: 2rem 0;
        width: 100%;
    }
    .app__footer-headtext {
        padding: 0;
    }
}

@media screen and (max-width: 350px) {
    .app__footer-links_logo img:first-child {
        width: 80%;
    }
    .app__footer-headtext {
        padding: 0;
    }
}
