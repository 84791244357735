.app__navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.app__navbar-logo img {
  height: 100px;
  width: 100px;
  padding-left: 1rem;
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding-right: 10px;
}

.app__navbar-links li a {
  margin: 0 1rem;
  cursor: pointer;
  color: black;
  font-family: base;
  text-decoration: none;
  position: relative;
  font-size: 1.5rem;
  font-family: 'helvetica';
}

.app__navbar-links li a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: transparent;
  transition: color 0.3s ease;
}

.app__navbar-links li a:hover::after {
  color: grey;
  border-bottom: 1px solid gold;
}

.app__navbar-smallscreen {
  display: none;
  text-align: center;
}

.app__navbar-smallscreen_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  transition: .5s ease;
  flex-direction: column;
  z-index: 5;
  align-content: center;
}

.app__navbar-smallscreen_overlay .overlay__close {
  font-size: 27px;
  color: goldenrod;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.app__navbar-smallscreen_links {
  list-style: none;
  padding: 10px;
  align-self: center;
}

.app__navbar-smallscreen_links li{
  padding: 10px;
}
.app__navbar-smallscreen_links li a {
  margin: 2rem;
  cursor: pointer;
  color: goldenrod;
  font-size: 2rem;
  text-align: center;
  font-family: base;
  text-decoration: none;
  font-family: 'helvetica';
}


.app__navbar-smallscreen_links li a:hover {
  color: #FD4B03;
}

@media screen and (min-width: 2000px) {
  .app__navbar-logo img {
    height: 80px;
    width: 80px;
  }
}

@media screen and (max-width: 1150px) {
  .app__navbar-links {
    display: none;
  }

  .app__navbar-smallscreen {
    display: flex;
  }
}

@media screen and (max-width: 650px) {
  .app__navbar-logo img {
    height: 80px;
    width: 80px;
  }
}

.contact-phone {
  background-color: #cf001a;
  border-radius: 20px;
  padding: 10px;
}

.contact-phone a {
  color:  #ffffff;
  font-family: base;
  text-decoration: none;
  position: relative;
  font-size: 20px;
  padding: 30px 10px 30px 5px;
}

.icon{
  padding-left: 5px;
  size: 1rem;
}