html {
  font-family: 'helvetica';
}

.order-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-form {
  width: 820px;
  height:fit-content;
  padding: 0px 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  text-align: center;
}

.form-label {
  display: block;
  justify-content: center;
  width: 100%;
  padding: 10px;
}


.submit-button {
  width: 50%; 
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #FFCA2A;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'helvetica';
  align-self: center;
}

.submit-button:hover {
  background-color: #fecc44;
}

.form-row {
  display: flex;
  justify-content: center;
  margin-right: 1rem;
}

.form-column {
  width: 48%;
}

@media (max-width: 768px) {
  .main{
    display: flex;
    flex-direction: column;
    height: 800px; 
    justify-content: space-between;
  }
  .form-label textarea{
    width: 240px;
  }
}

.error-message {
  color: #dc3545;
  font-size: 14px;
  margin-top: 5px;
}


.input-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  width: max-content;
 
}

.input3 {
  width: 90%;
  max-width: calc(100% - 20px); 
  height: 40px;
  border-radius: 50px;
  transition: .2s linear;
  border: 2.5px solid #fecc44;
  font-size: 14px;
  letter-spacing: 2px;
  padding: 5px;
  margin: 0;
  text-align: center;
}

.input3:focus {
  outline: none;
  border: 0.5px solid #fecc44;
  box-shadow: -5px -5px 0px #fecc44;
}

h1, h3{
  font-family: 'helvetica';
}

