.testimonials-container {
  position: relative; 
  background-image: url('../../assets/Images/2.jpg');
  padding: 20px;
  height: max-content;
}

.section-title {
  font-size: 2rem;
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
}

.testimonials {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative; 
}

.testimonial {
  flex: 0 0 calc(33.33% - 20px);
  max-width: 300px; 
  margin: 10px;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
  text-align: center;
}

.quote {
  font-style: italic;
  color: #666;
  margin-bottom: 10px;
}

.name {
  font-weight: bold;
  margin-bottom: 5px;
}

.role {
  color: #888;
}

.nav-button {
  background-color: transparent;
  border: none;
  font-size: 2rem;
  color: orange;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.nav-button.left {
  left: 10px;
}

.nav-button.right {
  right: 10px;
}

@media only screen and (max-width: 768px) {
  .testimonial {
    flex: 0 0 calc(50% - 20px); 
  }
}

@media only screen and (max-width: 480px) {
  .testimonial {
    flex: 0 0 calc(100% - 20px); 
  }
}
